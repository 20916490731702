import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../components/layoutV2"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInnerV2";
import * as PATHS from "../utils/paths";
import "../utils/index"
import {Button, Grid, Hidden} from "@material-ui/core";
import {WORKSHOPS_EQUITABLE_EPIDEMIOLOGY} from "../utils/paths";

const styles = theme => ({
    root: {},
    nhstug: {
      "& a": {
          color: "#0E0E0E",
          // textDecoration: "none",
      }
    },
    title: {
        fontSize: 64,
        lineHeight: 1.2,
        letterSpacing: -1.28,
        marginTop: 0,
        color: "#0E0E0E",
    },
    heroText: {
        fontSize: 20,
        lineHeight: 1.4,
        marginBottom: 24,
        color: "#0E0E0E",
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    header1: {
        borderTop: "1px solid #000",
        paddingTop: 12,
        fontSize: 44,
        lineHeight: 1.4,
        letterSpacing: -.88,
        marginBottom: 48,
        color: "#0E0E0E",
        // maxWidth: "50%",
        display: "inline-block",
    },
    header2: {
        fontSize: 28,
        lineHeight: 1.2,
        letterSpacing: -0.56,
        marginBottom: 12,
        color: "#0E0E0E",
    },
    smallText: {
        fontSize: 18,
        lineHeight: 1.4,
        marginBottom: 12,
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 16,
        // },
    },

    headImgWrap: {
        // maxWidth: "100%",
        position: "relative",
        // backgroundImage: "url(https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-head.png)",
        // height: "100%",
        // backgroundSize: "120%",
        display: "flex",
        flexDirection: "column",
        // flex: 1,
        // backgroundColor: "blue",
        minHeight: "100%",
        [theme.breakpoints.down('xs')]: {
            height: 180,
        },
        // height: 240,

    },


    headImg: {

        position: "absolute",
        // left: "-4%",
        // right: "-13.8%",
        left: "-8%",
        right: "-51%",
        // top: "-20%",
        bottom: "0%",
        // maxHeight: "120%",

        maxWidth: "159%",
        [theme.breakpoints.down('xs')]: {
            maxHeight: 360,
            // height: 240,
        },

    },

    spacer: {
      height: 96,
    },
    referenceList: {
        // paddingLeft: "1em",
        paddingLeft: 0,
        listStyleType: "none",
        lineHeight: 1,
        "& li": {
            // marginBottom: 12,
            fontSize: 18,
            lineHeight: 1.333,
            marginBottom: 24,
            // [theme.breakpoints.down('sm')]: {
            //     fontSize: 14,
            // },
        },
        "& b": {
            fontWeight: 600,
        }
    }
});



const SectionContainer = ({title, children}) => {
    return (
        <Grid container spacing={0} direction={'row'} style={{marginBottom: 96}}>
            <Grid item sm={4}><h3>{title}</h3></Grid>
            <Grid item sm={8}>
                {children}
            </Grid>

        </Grid>)
}


const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={false} className={classes.nhstug}>
            <SEO title={"National Health Service: Tableau User Group: Deathly Dataviz Talk Materials"} location={{pathname: "/nhstug2024"}}/>

            <LayoutInner>
                <h1 className={classes.title}>NHS TUG Materials</h1>
                <Grid container
                      spacing={0} direction={'row'}
                      sx={{
                          // justifyContent: "center",
                          alignItems: "stretch",
                      }}
                    // alignItems={"stretch"}
                >

                    <Hidden smUp>
                        <Grid item xs={12} sm={6} style={{
                            paddingBottom: 0,
                        }}>

                            <div className={classes.headImgWrap}>
                                {/*<div className={classes.headImgWrapInner}>*/}

                                <img className={classes.headImg}
                                     src={"https://3iap.com/cdn/images/3iap-nhs-tug-follow-up-head-v2.png"}/>
                                {/*</div>*/}
                            </div>



                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={5}>

                        <p className={classes.heroText}>
                            Thank you for the opportunity to share “Deathly Dataviz: A morbid introduction to
                            “Equitable Epidemiology.” Follow up materials are below for your convenience.
                        </p>

                        <p className={classes.heroText}>
                            If you have questions, comments, or are interested in further workshops or trainings,
                            please get in touch.
                        </p>

                        <Link to={PATHS.CONTACT_PAGE} style={{textDecoration: "none"}}>
                            <Button variant={"contained"} color={"primary"}>Get In Touch</Button>
                        </Link>
                    </Grid>
                    <Grid item sm={1}/>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={6} style={{
                            borderBottom: "1px solid #0E0E0E",
                            paddingBottom: 0,
                            // backgroundColor: "#F2F2F2",
                            // maxHeight: "920px",
                            // height: "100%"
                        }}>

                                <div className={classes.headImgWrap}>
                                    {/*<div className={classes.headImgWrapInner}>*/}

                                    <img className={classes.headImg}
                                         src={"https://3iap.com/cdn/images/3iap-nhs-tug-follow-up-head-v2.png"}/>
                                    {/*</div>*/}
                                </div>



                        </Grid>
                    </Hidden>


                </Grid>
                <Hidden xsDown>
                    <div className={classes.spacer}/>
                </Hidden>

                <div className={classes.spacer}/>

                <h1 className={classes.header1}>3iap Talks & Workshops</h1>

                <Grid container spacing={8} direction={'row'} style={{marginBottom: 48}}>
                <Grid item sm={6}>
                        <h2 className={classes.header2}>Equitable Epidemiology</h2>
                        <p className={classes.smallText}>
                            If you are interested in diving deeper into other topics related to equitable population
                            health visualizations,
                            or if you think these are important topics for your colleagues, you can learn more about the
                            "Equitable Epidemiology" workshops here:</p>
                        <Link to={PATHS.WORKSHOPS_EQUITABLE_EPIDEMIOLOGY} style={{textDecoration: "none"}}>
                            <Button variant={"contained"} color={"secondary"} fullWidth>Learn More</Button>
                        </Link>


                    </Grid>
                    <Grid item sm={6}>
                        <h2 className={classes.header2}>Other Talks</h2>
                        <ul className={classes.referenceList}>
                            <li>"<a href={"https://www.youtube.com/watch?v=rlNy3Z40Lp0"}>Visual Villainy: How to Undermine Humanity with Dataviz</a>" This
                                year’s Outlier Keynote
                            </li>
                            <li>"<a href={"https://www.youtube.com/watch?v=kjHhRx8hmAI"}>Chart Chat 53 with Eli Holder</a>" Chart Chat hosts Steve Wexler, Jeff
                                Shaffer, Amanda Makulec, and Andy Cotgreave are joined by Eli Holder, principal at 3 is
                                a Pattern and researcher who has dug into some sticky topics around how we represent (or
                                misrepresent) information through visualization.
                            </li>
                        </ul>
                    </Grid>

                </Grid>

                <h1 className={classes.header1}>3iap Research & Writing</h1>
                <Grid container spacing={8} direction={'row'} style={{marginBottom: 48}}>
                    <Grid item sm={6}>
                        <h2 className={classes.header2}>Unfair Comparisons (Dispersion vs Disparity)</h2>
                        <p className={classes.smallText}>Exploring the impact of variability on attribution and stereotypes.</p>
                        <ul className={classes.referenceList}>

                            <li><span>Research Paper:</span>&nbsp;
                                “<a href={"https://arxiv.org/abs/2208.04440"}>Dispersion vs Disparity: Hiding
                                    Variability Can
                                    Encourage Stereotyping When Visualizing Social Outcomes</a>”
                            </li>
                            <li><span>Nightingale Writeup:</span>&nbsp;
                                “<a href={"https://3iap.com/unfair-comparisons-how-visualizing-social-inequality-can-make-it-worse-ZTmaoCrsSeanEW00O2jnsQ/"}>Unfair
                                    Comparisons: How Visualizing
                                    Social Inequality Can Make It Worse</a>”
                                How popular chart choices can trigger unconscious social biases and reinforce systemic
                                racism.
                            </li>
                            <li><span>Technical Results Post:</span>&nbsp;
                                “<a href={"https://3iap.com/dispersion-disparity-equity-centered-data-visualization-research-project-Wi-58RCVQNSz6ypjoIoqOQ/"}>Dispersion
                                    & Disparity</a>”
                            </li>
                            <li><span>Motivation:</span>&nbsp;
                                “<a href={"https://3iap.com/what-can-go-wrong-racial-equity-data-visualization-deficit-thinking-VV8acXLQQnWvvg4NLP9LTA/"}>What
                                    Can Go Wrong? Deficit Thinking in Dataviz</a>”
                            </li>
                            <li><span>VIS Talk:</span>&nbsp;
                                “<a href={"https://www.youtube.com/watch?v=AyUkX_m94k0"}>Dispersion vs Disparity</a>”
                                Presentation for
                                IEEE VIS 2022
                            </li>

                        </ul>
                    </Grid>
                    <Grid item sm={6}>
                        <h2 className={classes.header2}>Must Be A Tuesday</h2>
                        <p className={classes.smallText}>Validating equitable data design techniques for population mortality charts.</p>
                        <ul className={classes.referenceList}>

                            <li><span>Research Paper:</span>&nbsp;
                                "<a href={"https://osf.io/c28wg"}>'Must Be a Tuesday': Affect, Attribution, and
                                    Geographic Variability
                                    inEquity-Oriented Visualizations of Population Health Disparities</a>"
                            </li>
                        </ul>
                    </Grid>

                    <Grid item sm={6}>
                        <h2 className={classes.header2}>Polarizing Political Polls</h2>
                        <p className={classes.smallText}>How social normative influences are triggered by charts</p>
                        <ul className={classes.referenceList}>

                            <li><span>Research Paper:</span>&nbsp;
                                "<a href={"https://arxiv.org/abs/2309.00690"}>Polarizing Political Polls: How Visualization Design Choices Can Shape
                                    Public Opinion and Increase Political Polarization</a>"
                            </li>
                            <li><span>Nightingale Writeup:</span>&nbsp;
                                “<a href={"https://nightingaledvs.com/divisive-dataviz-how-political-data-journalism-divides-our-democracy/"}>
                                    Divisive Dataviz: How Political Data Journalism Divides Our Democracy
                                </a>”

                            </li>
                            <li><span>Technical Results Post:</span>&nbsp;
                                “<a href={"https://3iap.com/polarizing-political-polls-dataviz-research-project-WPct6Y52Q6WwSEqJk0Mmeg/"}>Polarizing
                                    Political Polls</a>”

                            </li>
                            <li><span>Background Motivation:</span>&nbsp;
                                “<a href={"https://3iap.com/political-psychology-primer-for-information-designers-Ol4B1UakTqi42tjIS4l_Vw/"}>
                                    Through a Partisan Lens: How Prior Political Beliefs Override Information.</a>”
                            </li>
                            <li><span>VIS Talk:</span>&nbsp;
                                “<a href={"https://www.youtube.com/watch?v=Lbzr4AQ66Cs"}>Polarizing Political Polls</a>”
                                Presentation for IEEE VIS 2023
                            </li>

                        </ul>
                    </Grid>

                    <Grid item sm={6}>
                        <h2 className={classes.header2}>Misc. Related Writing</h2>
                        <ul className={classes.referenceList}>
                            <li>“<a href={"https://www.effaff.com/sketchy-bar-charts/"}>Sketching Sketchy Bar Charts</a>” Understanding how viewers
                                underestimate variability when viewing bar charts of averages.
                            </li>
                            <li>“<a href={"https://www.effaff.com/health-risk-data-contrast-effects/"}>When is eleven scarier than twelve?</a>” Understanding perceptions of
                                relative health risk.
                            </li>
                            <li>“<a href={"https://www.effaff.com/read-the-room-ensemble-effect/"}>Read the room</a>” The Ensemble Effect helps explain why Jitter Plots
                                so intuitive.
                            </li>
                        </ul>
                    </Grid>

                </Grid>

                <h1 className={classes.header1}>Talk Citations</h1>
                <Grid container spacing={8} direction={'row'} style={{marginBottom: 48}}>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Introduction</h2>
                        <ul className={classes.referenceList}>
                            <li>Rogers 2013: "<a
                                href={"https://www.theguardian.com/news/datablog/2013/mar/15/john-snow-cholera-map"}>
                                John Snow's data journalism: the cholera map that changed the world
                            </a>"
                            </li>
                            <li>Milkman et al. 2021: "<a href={"https://doi.org/10.1038/s41586-021-04128-4"}>
                                Megastudies improve the impact of applied behavioural science
                            </a>"
                            </li>

                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Information Backfire: What’s the worst that can happen?</h2>
                        <ul className={classes.referenceList}>

                            <li>Gold et al. 2018: "<a href={"https://doi.org/10.1037/hea0000681"}>
                                The Unintended Impact of Smoking-Risk Information on Concerns About Radon: A Randomized
                                Controlled Trial
                            </a>"
                            </li>
                            <li>Skinner-Dorkenoo 2022: "<a href={"https://doi.org/10.1016/j.socscimed.2022.114951"}>
                                Highlighting COVID-19 racial disparities can reduce support for safety precautions among
                                White U.S. residents
                            </a>"
                            </li>
                            <li>Mahler et al. 2003: "<a href={"https://doi.org/10.1037//0278-6133.22.2.199"}>
                                Effects of Appearance-Based Interventions on Sun Protection Intentions and Self-Reported
                                Behaviors
                            </a>"
                            </li>
                            <li>Mahler et al. 2010: "<a href={"https://doi.org/10.1007/s10865-010-9279-3"}>
                                Effects of upward and downward social comparison information on the efficacy of an
                                appearance-based sun protection intervention: a randomized, controlled experiment
                            </a>"
                            </li>
                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Pointing The Finger</h2>
                        <ul className={classes.referenceList}>

                            <li>Jhangiani & Tarry 2022: "<a
                                href={"https://opentextbc.ca/socialpsychology/chapter/biases-in-attribution/"}>
                                Biases in Attribution
                            </a>"
                            </li>
                            <li>Davis & Museus 2019: "<a href={"http://dx.doi.org/10.3998/currents.17387731.0001.110"}>
                                What Is Deficit Thinking? An Analysis of Conceptualizations of Deficit Thinking and
                                Implications for Scholarly Research
                            </a>"
                            </li>
                            <li>Holder & Padilla 2024: "<a href={"https://osf.io/c28wg"}>
                                Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented
                                Visualizations of Population Health Disparities
                            </a>"
                            </li>


                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Downstream Detriments</h2>
                        <ul className={classes.referenceList}>
                            <li>Cimpian & Salomon 2014: "<a href={"https://doi.org/10.1017/s0140525x13002197"}>
                                The inherence heuristic: An intuitive means of making sense of the world, and a
                                potential precursor to psychological essentialism
                            </a>"
                            </li>
                            <li>Kite & Whitley 2016: "<a href={"http://dx.doi.org/10.4324/9780367809218-5"}>
                                Old-fashioned and contemporary forms of prejudice
                            </a>"
                            </li>
                            <li>Leitner et al 2016: "<a href={"https://doi.org/10.1016/j.socscimed.2016.10.007"}>
                                Racial bias is associated with ingroup death rate for Blacks and Whites: Insights from
                                Project Implicit
                            </a>"
                            </li>
                            <li>Orchard et al 2017: "<a href={"https://doi.org/10.1016/j.socscimed.2017.03.036"}>
                                County-level racial prejudice and the black-white gap in infant health outcomes
                            </a>"
                            </li>
                            <li>Holder & Padilla 2024: "<a href={"https://osf.io/c28wg"}>
                                Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented
                                Visualizations of Population Health Disparities
                            </a>"
                            </li>
                            <li>Brown-Iannuzzi et al 2016: "<a href={"https://doi.org/10.1177/0956797616674999"}>
                                The Relationship Between Mental Representations of Welfare Recipients and Attitudes
                                Toward Welfare
                            </a>"
                            </li>
                            <li>Snowden & Graaf 2019: "<a href={"https://doi.org/10.1177/0095798419844129"}>
                                The 'Undeserving Poor,' Racial Bias, and Medicaid Coverage of African Americans
                            </a>"
                            </li>
                            <li>Gollust & Lynch 2011: "<a href={"https://doi.org/10.1215/03616878-1460578"}>
                                Who Deserves Health Care? The Effects of Causal Attributions and Group Cues on Public
                                Attitudes About Responsibility for Health Care Costs
                            </a>"
                            </li>
                            <li>Jhangiani & Tarry 2022: "<a
                                href={"https://opentextbc.ca/socialpsychology/chapter/other-determinants-of-helping/"}>
                                Other Determinants of Helping
                            </a>"
                            </li>
                            <li>Braveman & Dominguez 2021: "<a href={"https://doi.org/10.3389/fpubh.2021.689462"}>
                                Abandon 'Race.' Focus on Racism.
                            </a>"
                            </li>
                            <li>Hoffman et al 2016: "<a href={"https://doi.org/10.1073%2Fpnas.1516047113"}>
                                Racial bias in pain assessment and treatment recommend-ations, and false beliefs about
                                biological differences between blacks and whites
                            </a>"
                            </li>
                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Design Matters</h2>
                        <ul className={classes.referenceList}>
                            <li>Jhangiani & Tarry 2022: "<a
                                href={"https://opentextbc.ca/socialpsychology/chapter/social-categorization-and-stereotyping/"}>
                                Social Categorization and Stereotyping
                            </a>"
                            </li>
                            <li>Wilmer & Kerns 2022: "<a href={"https://osf.io/preprints/osf/av5ey"}>
                                What’s really wrong with bar graphs of mean values: variable and inaccurate
                                communication of evidence on three key dimensions
                            </a>"
                            </li>
                            <li>Holder & Xiong 2022: "<a href={"https://arxiv.org/abs/2208.04440"}>
                                Dispersion vs Disparity: Hiding Variability Can Encourage Stereotyping When Visualizing
                                Social Outcomes
                            </a>"
                            </li>
                            <li>Holder & Padilla 2024: "<a href={"https://osf.io/c28wg"}>
                                Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented
                                Visualizations of Population Health Disparities
                            </a>"
                            </li>
                            <li>Cimpian & Salomon 2014: "<a href={"https://doi.org/10.1017/s0140525x13002197"}>
                                The inherence heuristic: An intuitive means of making sense of the world, and a
                                potential precursor to psychological essentialism
                            </a>"
                            </li>
                            <li>Skitka et al 2002: "<a href={"https://doi.org/10.1037/0022-3514.83.2.470"}>
                                Dispositions, Scripts, or Motivated Correction? Understanding Ideological Differences in
                                Explanations for Social Problems
                            </a>"
                            </li>
                            <li>Matute et al 2015: "<a href={"https://doi.org/10.3389/fpsyg.2015.00888"}>
                                Illusions of causality: how they bias our everyday thinking and how they could be reduced
                            </a>"
                            </li>
                            <li>Kong et al 2019: "<a href={"https://doi.org/10.1145/3290605.3300576"}>
                                Trust and Recall of Information across Varying Degrees of Title-Visualization Misalignment
                            </a>"
                            </li>
                            <li>Xiong Bearfield 2024: "<a href={"https://arxiv.org/abs/2401.09289"}>
                                Same Data, Diverging Perspectives: The Power of Visualizations to Elicit Competing Interpretations
                            </a>"
                            </li>


                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <h2 className={classes.header2}>Is This Better?</h2>
                        <ul className={classes.referenceList}>

                            <li>Holder & Padilla 2024: "<a href={"https://osf.io/c28wg"}>
                            Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented
                                Visualizations of Population Health Disparities
                            </a>"
                            </li>


                        </ul>
                    </Grid>
                </Grid>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
